var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _vm.hasFlag
        ? _c("FormViewer", {
            key: _vm.formId + 1,
            ref: "homeViewer",
            staticClass: "con",
          })
        : _c("div", { staticClass: "empty" }, [
            _c("img", {
              attrs: { src: require("@/assets/image/index/null.jpg") },
            }),
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "lang_Please contact the administrator for configuration"
                  )
                )
              ),
            ]),
          ]),
      _c(
        "footer",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.formId == "08091708119380002" || _vm.formId == "null",
              expression: "formId == '08091708119380002' || formId == 'null'",
            },
          ],
        },
        [
          _vm._m(0),
          _c("p", { staticClass: "tips2" }, [
            _vm._v("诚东资产管理有限公司 版权所有"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "tips1" }, [
      _c("a", { attrs: { href: "https://www.gov.cn/", target: "_brank" } }, [
        _vm._v("中国政府网"),
      ]),
      _vm._v(" | "),
      _c(
        "a",
        { attrs: { href: "http://www.sasac.gov.cn/", target: "_brank" } },
        [_vm._v("国务院国有资产监督管理委员会")]
      ),
      _vm._v(" | "),
      _c("a", { attrs: { href: "https://www.cncec.cn/", target: "_brank" } }, [
        _vm._v("中国化学工程集团有限公司"),
      ]),
      _vm._v(" | "),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.cncec.com.cn/index/sy",
            target: "_brank",
          },
        },
        [_vm._v("中国化学工程股份有限公司")]
      ),
      _vm._v(" | 诚东资产管理有限公司 | 中化学资产管理有限公司 | 更多链接 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }