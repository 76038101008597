























import FormViewer from '@/components/form-home-viewer/form-viewer.vue';
import { Component, Vue, ProvideReactive, Watch } from 'vue-property-decorator';

@Component({
  name: 'HomeFormViewer',
  components: {
    FormViewer
  }
})
export default class extends Vue {
  @ProvideReactive()
  formId: any = '';
  hasFlag: boolean = true;
  @Watch('$route.query')
  formIdChange(val) {
    if (val && val.formId && val.formId != 'null') {
      this.formId = val.formId;
      this.hasFlag = true;
    }
  }

  created() {
    if (this.$route.query) {
      const query = this.$route.query;
      if (query && query.formId && query.formId != 'null') {
        this.formId = query.formId;
      } else {
        this.formId = 'null';
        this.hasFlag = false;
        // this.$message.error("没有formId！");
      }
    }
  }
}
